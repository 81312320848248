import React from 'react'
import {
  Button,
  Market,
  Geocode,
  OrderData,
  ProductCart,
  Zone,
  getAvailableDates,
  getStoredMarket,
  getStoredZone,
} from '@ecommerce/shared'
import { navigate } from 'gatsby'
import usePaymentHandler from '../../../hooks/usePaymentHandler'
import { useCheckout } from '../../../checkout.context'
import secrets from '../../../../../config/secrets'
import { ErrorLevel, sendMessageToSentry } from '../../../../../utils/sentry'

interface Props {
  disabled: boolean
  orderData: OrderData
  currentCity: Market
  currentZone: Zone
  geocode?: Geocode
  orderId: string
  sessionId: string
  buttonText: string
  checkStockCallback: (hasValidStock: boolean, products?: ProductCart[]) => void
  guestUser: { firstName?: string; lastName?: string }
  className?: string
}

const PaymentHandlerButton = ({
  checkStockCallback,
  disabled,
  orderData,
  currentCity,
  currentZone,
  geocode,
  orderId,
  sessionId,
  buttonText,
  guestUser,
  className,
}: Props) => {
  const { COUNTRY: country } = secrets

  const zoneId = getStoredZone()?.id
  const marketId = getStoredMarket()?.id

  const { setSelectPaymentError, setSelectTermstError } = useCheckout()
  const { loading, handleSubmit } = usePaymentHandler({
    checkStockCallback,
    orderData,
    currentCity,
    currentZone,
    geocode,
    orderId,
    sessionId,
    guestUser,
  })

  const verifyData = async () => {
    if (marketId && zoneId && orderData && orderData?.date) {
      const availableDates = await getAvailableDates(marketId, zoneId, country)

      if (!availableDates.map((date) => date.value).includes(orderData?.date)) {
        navigate('/cart')
        sendMessageToSentry({
          message: `error: Delivery date not available`,
          level: ErrorLevel.Warning,
          page: 'edit user',
          metadata: { error: { orderId, date: orderData.date, availableDates } },
        })
        return false
      }
      if (!orderData.payment || !orderData.payment_type) {
        setSelectPaymentError(true)
        return false
      }

      if (!orderData.terms) {
        setSelectTermstError(true)
        return false
      }

      if (disabled) {
        return false
      }

      handleSubmit()
    }
  }

  return (
    <Button
      data-test="checkout-payment-button"
      isLoading={loading}
      isDisabled={disabled || loading}
      title={buttonText}
      className={className}
      onClick={verifyData}
    >
      {buttonText}
    </Button>
  )
}

export default PaymentHandlerButton
