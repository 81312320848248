import React from 'react'
import { RadioInput as Input } from '@ecommerce/shared'
import { PaymentOptionWrapper, InputWrapper, RadioInput, CheckboxLabel } from './SelectPayment.styled'

type PaymentOptionProps = {
  items: Input[]
  name: string
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>, label?: string) => void | unknown
  dataTest?: string
  isDisabled?: boolean
  paymentSelected?: string | null
}

export const PaymentOption = ({
  items,
  onChange,
  name,
  className,
  dataTest,
  isDisabled = false,
  paymentSelected,
}: PaymentOptionProps) => (
  <PaymentOptionWrapper className={className}>
    {items?.map(({ value, label, image }, index) => (
      <InputWrapper key={`${label}-${index}`}>
        <CheckboxLabel>
          <RadioInput
            data-test={`${dataTest}-${index}`}
            onChange={(e) => onChange(e, label)}
            value={value}
            name={name}
            checked={label === paymentSelected}
            disabled={isDisabled}
          />
          <span>{label}</span>
        </CheckboxLabel>
        {image && <img src={image} alt={label} />}
      </InputWrapper>
    ))}
  </PaymentOptionWrapper>
)
