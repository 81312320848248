import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  Market,
  useLocation,
  FormattedAddress,
  useResolution,
  Zone,
  updateFavoriteAddress,
  useShoppingCart,
  Geocode,
  toCurrencyNumber,
  Order,
  Coordinates,
} from '@ecommerce/shared'
import { useFormContext } from 'react-hook-form'
import { navigate } from 'gatsby'
import { Wrapper, AlertTitle, AlertContent } from './MyDelivery.styled'
import AddressData from './AddressData'
import Card from '../../../Card'
import { sendMessageToSentry, ErrorLevel } from '../../../../utils/sentry'
import EmptyAddress from './EmptyAddress'
import MyAddresses from './MyAddresses'
import BottomSheet from '../../../BottomSheet'
import AddressForm from './AddressForm'
import ConfirmationAlert from '../../../ConfirmationAlert'
import { Icon } from '../../../Icon/Icon'
import DeliveryDate from './DeliveryDate'
import secrets from '../../../../config/secrets'
import SkeletonScreen from '../SkeletonScreen'
import { useCheckout } from '../../checkout.context'
import { updateFavorites } from '../../utils'
import { sendCheckoutEvent } from '../../../../utils/events'
import { tagManagerCheckoutActionFields } from '../../../../utils/gtm'
import OutOfRangeModal from './OutOfRangeModal'
import useMyDelivery from './useMyDelivery'

type Props = {
  currentMarket: Market
  currentZone: Zone
  geocode?: Geocode
  setGeocode: (geocode?: Geocode) => void
  orderCustomer: {
    isAuth?: boolean
    firstName: string | null
    lastName: string | null
    ownerId: string | null
  }
  setIsLoadingUserData: (isLoading: boolean) => void
  isLoadingUserData: boolean
  handleChange: (name: keyof Order, value: string, changeStep?: boolean) => void
}
const { COUNTRY } = secrets

const MyDelivery = ({
  orderCustomer,
  currentMarket,
  currentZone,
  setIsLoadingUserData,
  isLoadingUserData,
  geocode,
  setGeocode,
  handleChange,
}: Props) => {
  const addressFields: Array<keyof Order> = ['shipping_name', 'shipping_address', 'shipping_phone']

  const { isAuth } = orderCustomer

  const { state: cartContextState } = useShoppingCart()

  const {
    cartState,
    userAddresses,
    editingAddress,
    isAddingAddress,
    selectedAddress,
    setUserAddresses,
    setEditingAddress,
    setIsAddingAddress,
    setSelectedAddress,
    setAddressIsValidated,
    setDistrictState,
    districtState,
    resetDistrictState,
    currentStep,
    setIsSelectingAddress,
    isSelectingAddress,
  } = useCheckout()

  const {
    deleteAddress,
    createAddress,
    getAddresses,
    noAddressAvailable,
    savingAddress,
    setNoAddressAvailable,
    showOutOfRangeModal,
    setShowOutOfRangeModal,
  } = useMyDelivery({
    currentMarket,
    setIsLoadingUserData,
    orderCustomer,
    step: currentStep,
  })

  const [shouldClearAddress, setShouldClearAddress] = useState(false)

  // new states
  const [isChangingMarket, setIsChangingMarket] = useState(false)

  const { googleEmbedId: mapID } = currentZone

  const { isBolivia } = useLocation()

  const methods = useFormContext()

  const { trigger, clearErrors, errors, watch: watchFields } = methods

  const addressRef = useRef<string>()
  const addressesRef = useRef<FormattedAddress[]>([])

  const showAddressForm = editingAddress !== null || isAddingAddress

  const isBO = isBolivia()
  const { isDesktop } = useResolution()

  const shippingNameIsValid =
    watchFields('shipping_name') !== '' && watchFields('shipping_name') && geocode !== undefined

  const addressIsValid =
    shippingNameIsValid &&
    watchFields('shipping_address') !== '' &&
    watchFields('shipping_phone') !== '' &&
    errors.shipping_phone === undefined &&
    !errors.shipping_instructions

  useEffect(() => {
    try {
      if (!selectedAddress && !isAuth) setIsAddingAddress(true)
      // Get user address if is logged
      if (isAuth) {
        getAddresses()
      }
    } catch (e) {
      sendMessageToSentry({
        message: 'Checkout: init Error',
        page: currentMarket ? `/checkout` : 'checkout',
        level: ErrorLevel.Critical,
        metadata: {
          error: e?.response ?? e?.message ?? e,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (userAddresses.length === 0) {
      setNoAddressAvailable(true)
    } else {
      setNoAddressAvailable(false)
    }
  }, [userAddresses.length])

  useEffect(() => {
    if (selectedAddress) {
      handleChange('addressId', selectedAddress.id)
      handleChange('shipping_address', selectedAddress.shipping_address)
      handleChange('shipping_name', selectedAddress.shipping_name)
      handleChange('shipping_phone', selectedAddress.shipping_phone)
      handleChange('shipping_instructions', selectedAddress.shipping_instructions ?? '')
    }
  }, [selectedAddress])

  useEffect(() => {
    if (editingAddress?.zoneId) {
      const zone = currentMarket.zones.filter((z) => z.id === editingAddress?.zoneId)[0]
      setDistrictState((prev) => ({
        ...prev,
        selectedZone: zone,
      }))
      handleChange('shipping_address', editingAddress?.shipping_address)
    }
  }, [editingAddress?.zoneId])

  useEffect(() => {
    addressRef.current = editingAddress?.shipping_address

    if (editingAddress?.geocode) {
      const { latitude, longitude } = editingAddress.geocode
      if (latitude && longitude) setGeocode({ latitude, longitude, country: COUNTRY })
    }
  }, [editingAddress])

  useEffect(() => {
    if (isAddingAddress && isBO) {
      setDistrictState({
        ...districtState,
        selectedZone: currentZone,
      })
    }
  }, [isAddingAddress])

  const sendGTMCheckoutData = useCallback(
    ({ state = cartState, actionField }: { state?: typeof cartContextState; actionField?: Record<string, any> }) => {
      sendCheckoutEvent(
        Object.values(state.byHash).map((product) => ({
          ...product,
          price: toCurrencyNumber(product.price, isBO),
        })),
        state,
        isBO,
        actionField,
      )
    },
    [cartState],
  )

  const onAddressSelect = (val: string, gcode?: Geocode) => {
    // Save address
    handleChange('shipping_address', val)

    // Clear errors
    clearErrors('shipping_address')
    setShouldClearAddress(false)

    // Save address geocode
    if (gcode && editingAddress !== null) {
      setEditingAddress({ ...editingAddress, geocode: gcode })
    }

    addressRef.current = val

    setGeocode(gcode)
  }

  const onAddressInputChange = (val: string) => {
    const hasChanged = val !== addressRef.current

    handleChange('shipping_address', val)

    if (hasChanged) {
      setGeocode(undefined)
    }

    setAddressIsValidated(!hasChanged)
  }

  // Handle map click
  const handleMapClick = (value: string, gcode: Coordinates): void => {
    setGeocode({ latitude: gcode.lat, longitude: gcode.lng, country: COUNTRY })
    handleChange('shipping_address', value)
    setAddressIsValidated(false)

    clearErrors('shipping_address')

    addressRef.current = value
  }

  // Handle address form submit
  const handleAddressSubmit = async () => {
    // Validate fields
    await trigger(addressFields)

    // Check region
    if (districtState.checkZone && !districtState.selectedZone) {
      return setDistrictState((prev) => ({ ...prev, hasError: true }))
    }
    const shouldPass = errors.shipping_phone === undefined && errors.shipping_address === undefined
    const watchedFields = {
      shipping_address: watchFields('shipping_address'),
      shipping_number: watchFields('shipping_number'),
      phone: watchFields('shipping_phone'),
      instructions: watchFields('shipping_instructions'),
      shipping_name: watchFields('shipping_name'),
    }
    if (addressIsValid && shouldPass && !savingAddress) {
      await createAddress({
        geocode,
        setAddressIsValidated,
        userAddresses,
        sendGTMCheckoutData,
        watchedFields,
      })
    }
  }

  const handleDeleteAddress = async (id: string, parent?: string) => {
    if (selectedAddress?.id === id) {
      setSelectedAddress(null)
      handleChange('addressId', '')
      handleChange('shipping_address', '')
      handleChange('shipping_name', '')
      handleChange('shipping_phone', '')
      handleChange('shipping_instructions', '')
    }
    await deleteAddress(id, userAddresses, parent)
  }

  const handleAddressCheck = (isChecked: boolean, key: number) => {
    const address = isChecked ? userAddresses[key] : undefined

    // Update in state
    const newAddresseses = updateFavorites(userAddresses, isChecked, userAddresses[key].id)
    setSelectedAddress(address ?? null)
    setUserAddresses(newAddresseses)

    // Update in CommerceLayer
    updateFavoriteAddress(updateFavorites(addressesRef.current, isChecked, userAddresses[key].id))
  }

  const handleAddressBack = () => {
    if (isAddingAddress) {
      setIsAddingAddress(false)
    } else {
      setEditingAddress(null)
      setShouldClearAddress(false)
    }

    if (!watchFields('addressId')) {
      resetDistrictState()
    }
  }

  const clearAddresssInput = () => {
    handleChange('shipping_address', '')

    setShouldClearAddress(true)
    setGeocode(undefined)

    setShowOutOfRangeModal(false)
  }

  const handleOpenAddressForm = (address?: FormattedAddress) => {
    if (address) {
      setEditingAddress(address)
    } else {
      setIsAddingAddress(true)
    }
  }

  const handleChangeAddress = () => {
    if (isAuth) {
      setIsSelectingAddress(true)
    } else {
      setEditingAddress(selectedAddress)
    }
  }

  const handleChangeMarket = () => {
    navigate('/cart?show_location=true', { state: {} })
  }

  const render = () => {
    if (showAddressForm)
      return isDesktop || !isAuth ? (
        <Card>
          <AddressForm
            handleAddressBack={handleAddressBack}
            currentMarket={currentMarket}
            districtState={{
              state: districtState,
              dispatcher: setDistrictState,
            }}
            shouldClear={shouldClearAddress}
            setIsChangingMarket={setIsChangingMarket}
            setIsSelectingAddress={setIsSelectingAddress}
            editingAddress={editingAddress}
            handleAddressSubmit={handleAddressSubmit}
            onMapClick={handleMapClick}
            onSelect={(val, gcode) => onAddressSelect(val, gcode)}
            onInputChange={onAddressInputChange}
            isAuth={isAuth}
            addressIsValid={shippingNameIsValid}
          />
        </Card>
      ) : (
        <BottomSheet
          title="Agregar nueva dirección"
          buttonLabel="Guardar"
          onSubmit={handleAddressSubmit}
          isOpen={showAddressForm}
          onClose={() => setIsAddingAddress(false)}
          icon="location"
        >
          <AddressForm
            handleAddressBack={handleAddressBack}
            setIsSelectingAddress={setIsSelectingAddress}
            currentMarket={currentMarket}
            districtState={{
              state: districtState,
              dispatcher: setDistrictState,
            }}
            shouldClear={shouldClearAddress}
            setIsChangingMarket={setIsChangingMarket}
            editingAddress={editingAddress}
            handleAddressSubmit={handleAddressSubmit}
            onMapClick={handleMapClick}
            onSelect={(val, gcode) => onAddressSelect(val, gcode)}
            onInputChange={onAddressInputChange}
            isAuth={isAuth}
            addressIsValid={shippingNameIsValid}
          />
        </BottomSheet>
      )
    if (noAddressAvailable && !selectedAddress)
      return (
        <Card>
          <EmptyAddress
            currentMarket={currentMarket}
            handleOpenAddressForm={handleOpenAddressForm}
            setIsChangingMarket={setIsChangingMarket}
          />
        </Card>
      )
    if (selectedAddress && !isSelectingAddress)
      return (
        <>
          <Card>
            <AddressData selectedAddress={selectedAddress} handleChangeAddress={handleChangeAddress} />
          </Card>
          <Card>
            <DeliveryDate
              marketId={currentMarket.id}
              zoneId={selectedAddress.zoneId}
              onChange={(date) => {
                handleChange('date', date)
                sendGTMCheckoutData(tagManagerCheckoutActionFields.shippingDate(date))
              }}
            />
          </Card>
        </>
      )
    if ((!noAddressAvailable && isSelectingAddress) || !selectedAddress)
      return (
        <MyAddresses
          isSelectingAddress={isSelectingAddress}
          setIsSelectingAddress={setIsSelectingAddress}
          addresses={userAddresses}
          handleOpenAddressForm={handleOpenAddressForm}
          handleAddressCheck={handleAddressCheck}
          selectedAddress={selectedAddress}
          handleDeleteAddress={handleDeleteAddress}
        />
      )
  }

  const Content = () => (
    <AlertContent>
      <p>Al cambiar de ubicación tu carro de compra podría sufrir modificaciones. </p>
      ¿Estás seguro que quieres cambiar de ubicación?
    </AlertContent>
  )

  const Title = () => (
    <AlertTitle>
      <Icon iconId="location" size="20" />
      Cambiar ubicación de entrega
    </AlertTitle>
  )

  if (isLoadingUserData) {
    return <SkeletonScreen />
  }

  return (
    <Wrapper>
      {isChangingMarket && (
        <ConfirmationAlert
          onClose={() => setIsChangingMarket(false)}
          onBlur={() => setIsChangingMarket(false)}
          onConfirm={handleChangeMarket}
          confirmButtonText="Si, cambiar ubicación"
          cancelButtonText="No"
          description={<Content />}
          text={<Title />}
        />
      )}
      {showOutOfRangeModal && <OutOfRangeModal onClose={clearAddresssInput} mapID={mapID} />}
      {render()}
    </Wrapper>
  )
}

export default MyDelivery
