import { getStoredDistributionCenter, getStoredMarket, useShoppingCart } from '@ecommerce/shared'
import { useEffect, useState } from 'react'
import { useCheckout } from '../../checkout.context'
import secrets from '../../../../config/secrets'

type ErrorResponse = {
  message: string
  detail?: string
  code?: string
}
const GENERIC_ERROR_MESSAGE = 'Ha habido un error, por favor intenta de nuevo.'
const INVALID_COUPON_ERROR = 'El cupón que ingresaste no es válido.'

interface UseCouponInputProps {
  orderId: string
}

interface UseCouponInputReturn {
  errorState: { hasError: boolean; message: string }
  setCouponCode: (code: string) => void
  isSuccess: boolean
  isLoading: boolean
  onApplyCoupon: () => void
  onRemoveCoupon: () => void
  couponCode: string
}

export const useCoupon = ({ orderId }: UseCouponInputProps): UseCouponInputReturn => {
  const { COUNTRY } = secrets
  const { setIsLoadingCoupon, couponCode: appliedCoupon } = useCheckout()

  const [couponCode, setCouponCode] = useState(appliedCoupon ?? '')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(!!appliedCoupon)
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: '',
  })

  const distributionCenter = getStoredDistributionCenter()
  const currentMarket = getStoredMarket()

  const { applyCouponOrGiftCard, removeCouponOrGiftCard } = useShoppingCart()

  useEffect(() => {
    if (!appliedCoupon) {
      setCouponCode(appliedCoupon ?? '')
      setIsSuccess(!!appliedCoupon)
    }
  }, [appliedCoupon])

  const setError = (e: any) => {
    let errorData = { message: GENERIC_ERROR_MESSAGE } as ErrorResponse

    if (e?.response?.data?.error?.message) {
      const { message = INVALID_COUPON_ERROR, detail, code } = e?.response?.data?.error

      errorData = { ...errorData, message: message ?? GENERIC_ERROR_MESSAGE, detail, code }

      if (!e?.response?.data?.error?.code) {
        errorData.message = GENERIC_ERROR_MESSAGE
      }
    }

    setErrorState({
      hasError: true,
      message: errorData.message,
    })
  }

  const onApplyCoupon = async () => {
    try {
      if (!currentMarket) return

      setErrorState({ hasError: false, message: '' })
      setIsLoading(true)
      setIsLoadingCoupon(true)

      await applyCouponOrGiftCard({
        code: couponCode,
        orderId,
        country: COUNTRY,
        currentMarket,
        distributionCenterSlug: distributionCenter?.slug ?? '',
      })

      setCouponCode(couponCode)

      setIsSuccess(true)
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
      setIsLoadingCoupon(false)
    }
  }

  const onRemoveCoupon = async () => {
    try {
      if (!currentMarket) return
      setErrorState({ hasError: false, message: '' })
      setIsLoading(true)
      setIsLoadingCoupon(true)

      await removeCouponOrGiftCard({
        code: couponCode,
        orderId,
        country: COUNTRY,
        currentMarket,
        distributionCenterSlug: distributionCenter?.slug ?? '',
      })
      setIsSuccess(false)
      setCouponCode('')
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
      setIsLoadingCoupon(false)
    }
  }

  return {
    errorState,
    setCouponCode,
    isSuccess,
    isLoading,
    onApplyCoupon,
    onRemoveCoupon,
    couponCode,
  }
}
