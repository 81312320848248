import { getPaymentMethods, PaymentMethodsObject, PaymentTypes, useLocation } from '@ecommerce/shared'
import { useEffect, useMemo, useState } from 'react'
import secrets from '../../../../config/secrets'
import { getStaticPaymentMethods } from './config'
import { useCheckout } from '../../checkout.context'

const { COUNTRY } = secrets
type Props = {
  paymentSourceLogo?: string
}
const usePaymentMethods = ({ paymentSourceLogo }: Props) => {
  const { isBolivia } = useLocation()
  const isBO = isBolivia()

  const { setProcessErrors } = useCheckout()

  const [paymentIsOnline, setPaymentIsOnline] = useState(false)
  const [paymentType, setPaymentType] = useState<PaymentTypes>(isBO ? PaymentTypes.REDENLACE : PaymentTypes.WEBPAY)

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsObject>({
    options: [],
    types: [],
  })

  const QRID = paymentMethods.types.filter((type) => type.QRID)[0]?.QRID

  const staticPaymentMethods = useMemo(() => getStaticPaymentMethods(QRID), [QRID])

  useEffect(() => {
    setPaymentIsOnline(
      paymentType === PaymentTypes.REDENLACE ||
        paymentType === PaymentTypes.WEBPAY ||
        paymentType === PaymentTypes.CHEK ||
        paymentType === PaymentTypes.MACH,
    )
  }, [paymentType])

  const fetchPayments = async () => {
    try {
      const payments = await getPaymentMethods(COUNTRY)
      setPaymentMethods(payments)
      setProcessErrors((prev) => ({ ...prev, paymentMethods: false }))
    } catch (error) {
      setProcessErrors((prev) => ({ ...prev, paymentMethods: true }))
    }
  }

  const paymentItems = useMemo(() => {
    if (paymentIsOnline) {
      return isBO
        ? paymentMethods.options.map((option) => {
            const options = option
            options.image = paymentSourceLogo
            if (!paymentSourceLogo) delete options.image
            return options
          })
        : paymentMethods.options
    }
    return staticPaymentMethods
  }, [paymentMethods, paymentIsOnline, isBO])

  return {
    paymentIsOnline,
    paymentItems,
    staticPaymentMethods,
    fetchPayments,
    paymentMethods,
    paymentType,
    setPaymentType,
  }
}

export default usePaymentMethods
